import React from 'react'
import PropTypes from 'prop-types'
import SplashAnimation from './components/SplashAnimation'

const WrapRootElement = ({ element }) => {
  return (
    <>
      <SplashAnimation />

      {element}
    </>
  )
}

WrapRootElement.propTypes = {
  element: PropTypes.any,
}

export default WrapRootElement
