import React, { useState } from 'react'
import { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { colors, screenSizes } from '../theme'

const Container = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.secondary};
  transition: all ease 300ms;
  cursor: none;

  &.animation-done {
    pointer-events: none;
    opacity: 0;
  }
`

const TextAnimation = keyframes`
  0% {
   transform: translateY(200%);
  }

  25% {
   transform: translateY(0%);
  }

  75% {
   transform: translateY(0%);
  }

  100% {
   transform: translateY(-200%);
  }
`

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  flex-direction: column;
`

const Line = styled.p`
  display: block;
  overflow: hidden;
  font-size: 22pt;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 0;

  span {
    transform: translateY(200%);
    color: ${colors.secondaryText};
    font-family: 'TanMemories';
    display: inline-block;
  }

  @media (min-width: ${screenSizes.tablet}px) {
    font-size: 40pt;
  }

  &.animate {
    span {
      animation: ${TextAnimation} 2s
        ${({ transitionDelay }) => transitionDelay || 0}ms ease-in-out forwards;
    }
  }
`

const SplashAnimation = () => {
  const [animationDone, setAnimationDone] = useState(false)

  const currentYear = new Date().getFullYear()

  useEffect(() => {
    let timeout

    window.setTimeout(() => {
      setAnimationDone(true)
    }, 3000)

    return () => {
      window.clearTimeout(timeout)
    }
  }, [])

  return (
    <Container className={animationDone ? 'animation-done' : ''}>
      <Text>
        <Line className="animate" transitionDelay={0}>
          <span>Teamgeek</span>
        </Line>
      </Text>
      <Text>
        <Line className="animate" transitionDelay={1500}>
          <span>2012 - {currentYear}</span>
        </Line>
      </Text>
    </Container>
  )
}

export default SplashAnimation
