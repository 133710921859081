import React from 'react'
import PageTransition from './components/PageTransition'
import GlobalStyles from './components/GlobalStyles'

// eslint-disable-next-line react/prop-types
const wrapPageElement = ({ element, props }) => {
  return (
    <PageTransition {...props}>
      <GlobalStyles />
      {element}
    </PageTransition>
  )
}

export default wrapPageElement
