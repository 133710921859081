import ModeratRegularEot from '../static/fonts/Moderat-Regular.eot'
import ModeratRegularTtf from '../static/fonts/Moderat-Regular.ttf'
import ModeratRegularWoff from '../static/fonts/Moderat-Regular.woff'
import ModeratRegularWoff2 from '../static/fonts/Moderat-Regular.woff2'
import PPNeueMontrealRegularEot from '../static/fonts/PPNeueMontreal-Regular.eot'
import PPNeueMontrealRegularTtf from '../static/fonts/PPNeueMontreal-Regular.ttf'
import PPNeueMontrealRegularWoff from '../static/fonts/PPNeueMontreal-Regular.woff'
import PPNeueMontrealRegularWoff2 from '../static/fonts/PPNeueMontreal-Regular.woff2'
import TanMemoriesItalicEot from '../static/fonts/TANMEMORIES-Italic.eot'
import TanMemoriesItalicTtf from '../static/fonts/TANMEMORIES-Italic.ttf'
import TanMemoriesItalicWoff from '../static/fonts/TANMEMORIES-Italic.woff'
import TanMemoriesItalicWoff2 from '../static/fonts/TANMEMORIES-Italic.woff2'
import TanMemoriesRegularEot from '../static/fonts/TANMEMORIES-Regular.eot'
import TanMemoriesRegularTtf from '../static/fonts/TANMEMORIES-Regular.ttf'
import TanMemoriesRegularWoff from '../static/fonts/TANMEMORIES-Regular.woff'
import TanMemoriesRegularWoff2 from '../static/fonts/TANMEMORIES-Regular.woff2'

export const fonts = {
  ModeratRegularEot,
  ModeratRegularTtf,
  ModeratRegularWoff,
  ModeratRegularWoff2,
  PPNeueMontrealRegularEot,
  PPNeueMontrealRegularTtf,
  PPNeueMontrealRegularWoff,
  PPNeueMontrealRegularWoff2,
  TanMemoriesItalicEot,
  TanMemoriesItalicTtf,
  TanMemoriesItalicWoff,
  TanMemoriesItalicWoff2,
  TanMemoriesRegularEot,
  TanMemoriesRegularTtf,
  TanMemoriesRegularWoff,
  TanMemoriesRegularWoff2,
}

export const gutter = {
  mobile: 30,
  tablet: 60,
  desktop: 60,
  desktopLarge: 90,
}

export const screenSizes = {
  tablet: 768,
  desktop: 992,
  desktopLarge: 1440,
  desktopExtraLarge: 1600,
}

export const colors = {
  primary: '#e3e3e3',
  secondary: '#1d1d1d',
  footer: '#161616',
  primaryText: '#1d1d1d',
  secondaryText: '#ffffff',
  footerText: '#adadad',
}

export const glitchTransitionDuration = 5000
