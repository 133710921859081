import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TweenLite, Expo } from 'gsap'
import { colors } from '../theme'

const BackgroundBlack = styled.div`
  background-color: #1d1d1d;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 100%;
  cursor: none;
`

const ContentContainer = styled.div``

class PageTransition extends Component {
  constructor(props) {
    super(props)
    this.state = {
      render: true,
    }
  }

  componentDidMount() {
    this.setState({ render: false })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      TweenLite.to(this.contentContainer, 0.75, {
        y: -100,
        opacity: 0,
        ease: Expo.easeInOut,
      })
      TweenLite.to(this.backgroundBlack, 0.75, {
        y: '-100%',
        delay: 0.2,
        ease: Expo.easeInOut,
      }).eventCallback('onComplete', () => {
        document.body.style.backgroundColor = colors.primary
        this.setState({ render: true })
      })
    }
  }

  componentDidUpdate() {
    this.setState({ render: false })
    if (this.state.render) {
      TweenLite.to(this.contentContainer, 0.75, {
        y: '0',
        opacity: 1,
        delay: 0.2,
        ease: Expo.easeInOut,
      }).eventCallback('onComplete', () => {
        this.contentContainer.style.transform = ''
      })
      TweenLite.to(this.backgroundBlack, 0.75, {
        y: '100%',
        delay: 0.1,
        ease: Expo.easeInOut,
      }).eventCallback('onComplete', () => {
        window.scrollTo(0, 0)
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.render
  }

  render() {
    const { children } = this.props

    return (
      <>
        <div />
        <BackgroundBlack ref={ref => (this.backgroundBlack = ref)} />
        <ContentContainer ref={ref => (this.contentContainer = ref)}>
          {children}
        </ContentContainer>
      </>
    )
  }
}

PageTransition.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any,
}

export default PageTransition
