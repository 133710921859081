import React, { useState, useEffect } from 'react'
import { createGlobalStyle, css } from 'styled-components'
import { fonts, screenSizes, colors } from '../theme'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${colors.primary};
  }

  @font-face {
    font-family: 'PPNeueMontreal';
    font-style: normal;
    font-display: auto;
    font-weight: normal;
    src: url(${fonts.PPNeueMontrealRegularEot}) format('embedded-opentype'),
       url(${fonts.PPNeueMontrealRegularWoff2}) format('woff2'),
       url(${fonts.PPNeueMontrealRegularWoff}) format('woff'),
      url(${fonts.PPNeueMontrealRegularTtf}) format("truetype");
  }

  @font-face {
    font-family: 'Moderat';
    font-style: normal;
    font-display: auto;
    font-weight: normal;
    src: url(${fonts.ModeratRegularEot}) format('embedded-opentype'),
       url(${fonts.ModeratRegularWoff2}) format('woff2'),
       url(${fonts.ModeratRegularWoff}) format('woff'),
      url(${fonts.ModeratRegularTtf}) format("truetype");
  }

  @font-face {
    font-family: 'TanMemories';
    font-style: italic;
    font-display: auto;
    font-weight: normal;
    src: url(${fonts.TanMemoriesItalicEot}) format('embedded-opentype'),
       url(${fonts.TanMemoriesItalicWoff2}) format('woff2'),
       url(${fonts.TanMemoriesItalicWoff}) format('woff'),
      url(${fonts.TanMemoriesItalicTtf}) format("truetype");
  }

  @font-face {
    font-family: 'TanMemories';
    font-style: normal;
    font-display: auto;
    font-weight: normal;
    src: url(${fonts.TanMemoriesRegularEot}) format('embedded-opentype'),
       url(${fonts.TanMemoriesRegularWoff2}) format('woff2'),
       url(${fonts.TanMemoriesRegularWoff}) format('woff'),
      url(${fonts.TanMemoriesRegularTtf}) format("truetype");
  }

  img {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  svg, span {
   font-family: 'Moderat';
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #1d1d1d;
    -webkit-font-smoothing: antialiased;
  }

  p, a, li {
    font-family: 'Moderat';
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #1d1d1d;
    font-size: 7pt;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    -webkit-tap-highlight-color: transparent;
    font-family: 'Moderat', Arial, Helvetica, sans-serif;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-font-smoothing: antialiased;
  }

  @media(min-width: ${screenSizes.tablet}px) {
    p {
      font-size: 7pt;
    }
  }

  ${props =>
    props.isChrome &&
    css`
      @media (min-width: ${screenSizes.tablet}px) {
        .swiper-wrapper {
          transition-timing-function: ease-out !important;
          transition-duration: 500ms !important;
        }

        .swiper-fast-snap {
          .swiper-wrapper {
            transition-duration: 200ms !important;
          }
        }
      }
    `}
`

const GlobalStyles = () => {
  const [isChrome, setIsChrome] = useState()

  useEffect(() => {
    setIsChrome(navigator && navigator?.userAgent?.indexOf('Chrome') > 0)
  }, [])

  return <GlobalStyle isChrome={isChrome} />
}

export default GlobalStyles
