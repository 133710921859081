import './src/components/layout.css'
import 'react-id-swiper/src/styles/css/swiper.css'
import wrapRootElement from './src/wrapRootElement'
import wrapPageElement from './src/wrapPageElement'

const shouldUpdateScroll = () => {
  return false
}

export { wrapRootElement, wrapPageElement, shouldUpdateScroll }
